import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Section, Title, Text } from "./Core";
import PageWrapper from "./PageWrapper";
import SportsRoll from "./SportsRoll";

const Sports = (props) => {
  return (
    <PageWrapper footerDark>
      <Section className="pb-0">
        <div className="pt-5 pt-lg-0"></div>
        <Container>
          <Row className="justify-content-md-center text-md-center">
            <Col lg="8">
              <Title>
                <FormattedMessage id="sports.heading" defaultMessage="Sports" />
              </Title>
              <Text>
                <FormattedMessage
                  id="sports.subheading"
                  defaultMessage="At Tournify, we aim to support as many tournament or league organizers as possible. Therefore, we're proud to be able to collaborate with great partners who share our passion for sports and eSports."
                />
              </Text>
            </Col>
          </Row>
        </Container>
      </Section>
      <div className="pt-5"></div>
      <SportsRoll data={props.data} />
      <div className="pt-3"></div>
    </PageWrapper>
  );
};

export default Sports;
